import { ContactFormMessagesProps } from './types';

const messages: ContactFormMessagesProps = {
  common: {
    required: 'Required',
  },
  email: {
    name: 'email',
    placeholder: 'Enter your email address',
    invalid: 'Please correct your email address',
  },
  message: {
    name: 'message',
    placeholder: 'Write your message here',
  },
  privacy: {
    name: 'privacy',
    invalid: 'Terms of service and privacy policy must be accepted',
    label: 'I accept Lynge North\'s terms and conditions and privacy policy',
  },
  button: {
    label: 'Send email',
  },
  submit: {
    success: 'The message was successfully sent!',
    invalid: 'Sending the message has failed!',
  },
};

export default messages;
