import { FormikConfig } from 'formik';

import { FormResponse } from './enums';
import { ContactFormValuesProps } from './types';

const URL_VALUE = '/api/sendgrid-contact';

const useOnSubmit = () => {
  const onSubmit: FormikConfig<ContactFormValuesProps>['onSubmit'] = async (
    data,
    { resetForm, setStatus }
  ) => {
    try {
      const response = await fetch(URL_VALUE, {
        method: `POST`,
        body: JSON.stringify(data),
        headers: {
          'content-type': `application/json`,
        },
      });

      if (!response.ok) {
        return setStatus(FormResponse.Fail);
      }

      resetForm();

      setTimeout(() => {
        setStatus(undefined);
      }, 4000);

      setStatus(FormResponse.Success);
    } catch {
      setStatus(FormResponse.Fail);
    }
  };

  return { onSubmit };
};

export default useOnSubmit;
