import styled from 'styled-components';

import media from '../../../../common/MediaQueries';
import { HeroContentInner } from '../styles';

export const HeroFormWrapper = styled.div`
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${media.phone} {
    justify-content: flex-end;
    width: 50%;
    margin-top: 0;
  }
  @media ${media.tablet} {
    width: 100%;
    max-width: 24.125rem;
  }
`;

export const HeroWithContactForm = styled.div`
  ${HeroContentInner} {
    @media ${media.maxTablet} {
      text-align: left;
    }
  }
`;

export const HeroForm = styled.div`
  position: relative;
  padding: 2.5rem;
  background-color: ${({ theme }) => theme.colors.neutral.neutral10};

  @media ${media.tablet} {
    padding: 3.75rem;
  }
`;
