import { graphql } from 'gatsby';
import React from 'react';

import { getImage } from '../../../../utils/sanityTypes';
import HeroWithContactForm from '../../../organisms/Hero/HeroWithContactForm';

import { HeroWithContactFormSectionProps } from './types';

const HeroWithContactFormSection: React.VFC<HeroWithContactFormSectionProps> = ({
  scrollButtonLabel,
  subtitle,
  title,
  contact,
  images,
}) => (
  <HeroWithContactForm
    data={{
      scrollButtonLabel: scrollButtonLabel ?? '',
      subtitle: subtitle ?? '',
      title: title ?? '',
      images: (images ?? []).map((singleImage) => {
        const { _key: key, image, mobileImage } = singleImage ?? {};
        return {
          key: key ?? '',
          image: getImage(image),
          mobileImage: getImage(mobileImage),
        };
      }),
      contact: (contact ?? []).map((singleContact) => {
        const { _key = '', title: contactTitle, desc } = singleContact ?? {};

        return {
          key: _key,
          title: contactTitle ?? '',
          desc: desc ?? undefined,
        };
      }),
    }}
  />
);

export const fragment = graphql`
  fragment HeroWithContactFormSectionFragment on SanityHeroWithContactFormSection {
    _key
    _type
    title
    subtitle
    content: _rawContent(resolveReferences: { maxDepth: 10 })
    scrollButtonLabel
    images {
      _key
      image {
        ...ImageFragment
      }
      mobileImage {
        ...ImageFragment
      }
    }
    contact {
      _key
      title
      desc: _rawDesc(resolveReferences: { maxDepth: 10 })
    }
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
`;

export default HeroWithContactFormSection;
