import React from 'react';

import * as Styled from './styles';

import HeroBase from '..';
import { HeroWithContactFormProps } from './types';
import ContactForm from '../../Form/Contact';

const HeroWithContactForm: React.FC<HeroWithContactFormProps> = ({ data }) => (
  <Styled.HeroWithContactForm>
    <HeroBase {...{ data }} hasContactForm>
      <Styled.HeroFormWrapper>
        <Styled.HeroForm>
          <ContactForm />
        </Styled.HeroForm>
      </Styled.HeroFormWrapper>
    </HeroBase>
  </Styled.HeroWithContactForm>
);

export default HeroWithContactForm;
