import * as Yup from 'yup';

import messages from './messages';
import { ContactFormValuesProps } from './types';

export const initialValues: ContactFormValuesProps = { email: '', message: '', privacy: false };

export const validationSchema = Yup.object().shape({
  email: Yup.string().required(messages.common.required).email(messages.email.invalid),
  message: Yup.string().required(messages.common.required),
  privacy: Yup.bool().required(messages.common.required).oneOf([true], messages.privacy.invalid),
});
