import React from 'react';
import { FieldProps, Formik } from 'formik';
import { AnimatePresence } from 'framer-motion';

import * as Styled from '../styles';
import { validationSchema, initialValues } from './consts';
import messages from './messages';
import Loader from '../../../atoms/Loader';
import useOnSubmit from './hooks';
import { FormResponse } from './enums';

const ContactForm: React.FC = () => {
  const { email, message, privacy, button, submit } = messages;
  const { onSubmit } = useOnSubmit()

  return (
    <Formik {...{ initialValues, onSubmit, validationSchema }}>
      {({ touched, errors, isSubmitting, handleSubmit, status }) => (
        <Styled.FormBox onSubmit={handleSubmit}>
          <Styled.FormRow
            hasIcon
            hasError={touched.email && !!errors.email}
            isValidated={touched.email && !errors.email}
          >
            <Styled.FormField type="email" name={email.name} placeholder={email.placeholder} />
            {touched.email && errors.email && (
              <Styled.FormError name={email.name} component="div" />
            )}
          </Styled.FormRow>
          <Styled.FormRow
            hasIcon
            hasError={touched.message && !!errors.message}
            isValidated={touched.message && !errors.message}
          >
            <Styled.FormField name={message.name} placeholder={message.placeholder}>
              {({ field: { value, onChange } }: FieldProps) => (
                <Styled.FormTextarea
                  {...{ value, onChange }}
                  name={message.name}
                  placeholder={message.placeholder}
                />
              )}
            </Styled.FormField>
            {touched.message && errors.message && (
              <Styled.FormError name={message.name} component="div" />
            )}
          </Styled.FormRow>
          <Styled.FormRow hasError={touched.privacy && !!errors.privacy}>
            <Styled.FormLabel as="label">
              <Styled.FormField type="checkbox" name={privacy.name} />
              <Styled.FormLabelText variant="textS">{privacy.label}</Styled.FormLabelText>
            </Styled.FormLabel>
            {touched.privacy && errors.privacy && privacy && (
              <Styled.FormError name={privacy.name || 'privacy'} component="div" />
            )}
          </Styled.FormRow>
          <Styled.FormBtn type="submit" disabled={isSubmitting}>
            {button.label}
          </Styled.FormBtn>
          <AnimatePresence>
            {!!status && (
              <Styled.FormMessage
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2, type: 'tween' }}
                hasSucceed={status === FormResponse.Success}
              >
                {status === FormResponse.Success ? submit.success : submit.invalid}
              </Styled.FormMessage>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {isSubmitting && (
              <Styled.FormLoader
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2, type: 'tween' }}
              >
                <Loader isVisible />
              </Styled.FormLoader>
            )}
          </AnimatePresence>
        </Styled.FormBox>
      )}
    </Formik>
  );
};

export default ContactForm;
